import axios from "axios"

// export const baseURL = 'http://localhost:4001'
export const baseURL = 'https://partner.li-gong.com/api/'
/**
 * 如果比较有空，可以完善图片以下内容：
 *      接口处判断图片大小
 *      较小的图片可以返回base64格式，
 *      文件较大的直接返回链接
 */
export const imgURL = baseURL + "/b_img/"

// 创建axios实例
const instance = axios.create({ timeout: 3000 * 60, baseURL });

instance.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
}
)

instance.interceptors.response.use(response => {
    return response.data
}, error => {
    console.log(error)
    return error
})

export default instance