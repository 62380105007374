import axios from "./axios"
import qs from "qs"


/**
 * 这两个接口存放在 经销商后台(dealer_background)
 */
export const getResult = (params: string) => {
    return axios.get("/open/args/search/" + params)
}

export const saveRepo = (params: any) => {
    return axios.post("/open/chose/post", qs.stringify(params))
}